import { graphql } from 'gatsby'
import get from 'lodash/get'
import Img from 'gatsby-image'
import React from 'react'

import { siteMetadata } from '../../gatsby-config'
import Layout from 'components/layout'
import Meta from 'components/meta'
import Icon from 'components/icon'

class Profile extends React.Component {
  render() {
    const { location, data } = this.props
    const profile = get(data, 'profile.childImageSharp.fixed')

    return (
      <Layout location={location}>
        <Meta site={siteMetadata} title="Profile" />
        <div>
          <section className="text-center">
            <div className="container">
              <Img fixed={profile} className="rounded-circle" />
              <h1>Patrick Blaas</h1>
              <p className="lead text-muted">Product Owner</p>
            </div>
          </section>

          <section className="bg-primary text-white text-center">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <h2 className="section-heading">SKILL</h2>
                  <hr className="border-white" />
                </div>
              </div>
            </div>
            <div className="container">
              <div className="row justify-content-md-center">
                <div className="col-lg-3 col-6">
                  <Icon title="HTML" name="html5" />
                </div>
                <div className="col-lg-3 col-6">
                  <Icon title="JavaScript" name="js" />
                </div>
                <div className="col-lg-3 col-6">
                  <Icon title="React.js" name="react" />
                </div>
              </div>
              <div className="row justify-content-md-center">
                <div className="col-lg-3 col-6">
                  <Icon title="Node.js" name="node" />
                </div>
                <div className="col-lg-3 col-6">
                  <Icon title="Docker" name="docker" />
                </div>

              </div>
            </div>
          </section>

          <section id="features" className="text-center jumboimage">
  
            <div className="container">
              <div className="row cover-over">
                <div className="col-md-12 text-left">
                  <h2 className="section-heading">Intro</h2>
                  <p>
                    I am currently employed as product owner at a Saab technologies B.V.
                    <br />
                    Previously I was a Linux system engineer at a hosting company. 
                    For more details on my work experience check LinkedIn and Github.
                    <br />
                  <div>
                  <ul style={{padding: "0", "text-align": "left"}}>
                  <li style={{display: "inline-block"}}><a href={siteMetadata.githubUrl}><Icon title="Github" name="github" /></a></li>           
                  <li style={{display: "inline-block", padding: "0 0 0 15px"}}><a href={siteMetadata.linkedinUrl}><Icon title="LinkedIN" name="linkedin" /></a></li>
                  </ul>
                  </div>
            
                  </p>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    )
  }
}

export default Profile

export const query = graphql`
  query ProfilePageQuery {
    profile: file(name: { eq: "profile" }) {
      childImageSharp {
        fixed(width: 120, height: 120) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    back1: file(name: { eq: "back1" }) {
      childImageSharp {
        sizes(quality: 100) {
          ...GatsbyImageSharpSizes_withWebp
        }
      }
    }
    back2: file(name: { eq: "back2" }) {
      childImageSharp {
        sizes(quality: 100) {
          ...GatsbyImageSharpSizes_withWebp
        }
      }
    }
  }
`
